$ketto-color: #01bfbd;
$default-text-color: #444444;

:root {
  --primary-color: #01bfbd;
  --primary-color-shadow: #d7efef;
  --primary-color-shadow-2: rgb(1 191 189 / 20%);
  --primary-hover-color: #039b9a;
  --green-color: #039695;
  --primary-color-rgb: 1, 191, 189;
  --primary-font-family: 'Source Sans Pro', sans-serif;
  --rboto-font-family: 'Roboto', sans-serif;
  --poppins-font-family: 'Poppins', sans-serif;
  --payment-sip-color: #039695;
  --payment-sip-color-bg: 3, 150, 149;
  --primary-color-shadow-05: rgb(1, 191, 189, 0.05);
  --primary-color-shadow: rgb(1, 191, 189, 0.1);
  --primary-color-shadow-2: rgb(1, 191, 189, 0.2);
  --primary-color-shadow-3: rgb(1, 191, 189, 0.3);
  --primary-color-shadow-4: rgb(1, 191, 189, 0.4);
  --primary-color-shadow-5: rgb(1, 191, 189, 0.5);
  --primary-color-shadow-6: rgb(1, 191, 189, 0.6);
  --primary-color-shadow-7: rgb(1, 191, 189, 0.7);
  --primary-color-shadow-8: rgb(1, 191, 189, 0.8);
  --primary-color-shadow-9: rgb(1, 191, 189, 0.9);
}

$default-font: var(--primary-font-family);

// Colors
$primary-color: var(--primary-color);
$primary-color-sip: var(--green-color);
$primary-hover-color: var(--primary-hover-color);
$primary-color-rbg: var(--primary-color-rgb);
$payment-sip-color: var(--payment-sip-color);
$gray-dark: #444444;
$gray-light: #999999;
$gray-light-1: #FBFBFB;
$gray-light-2: #CCCCCC;
$gray-light-3: #F7F7F7;
$gray-light-4: #EEEEEE;
$gray-light-5: #F5F5F5;
$white: #ffffff;

// Social Brand Colors
$facebook: #393D74;
$twitter: #2c99d7;
$linkedin: #0077B5;
$whatsapp: #13cb6a;
$gmail: #ea4335;
$error: #ea4335;

/* Using variables to store breakpoints */
$breakpoint-desktop: 767px;
$breakpoint-mobile: 768px;

$darkerClick: #01807E !default;
$lighterClick: #0da5a2 !default;

$darker: #414995 !default;
$lighter: #555FC4 !default;